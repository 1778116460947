
import down from '../assets/images/down.png'
import sleeptracker from '../assets/images/sleeptracker.png'
import sbrowser from '../assets/images/spotifybrowser.png'

export default [
    {
        id: 1,
        title: "Down",
        icon: down,
        description1: "Capstone Project Course: Project in Software System Design. ",
        description2: "Built a mobile application, a service for users to explore, join and create local events",
        githubPath: null,
        demoPath: null,
        year: "2021",
        techUsed: "Adobe XD, React Native"
    },
    {
      id: 2,
      title: "Sleep Tracker",
      description1: "Mobile application that tracks overnight sleep and sleepiness levels during the day. Data is analyzed and displayed as insights.",
      icon: sleeptracker,
      githubPath: "https://www.google.com/url?q=https%3A%2F%2Fgithub.com%2Fcheryllg%2FSleep-Tracker-Ionic&sa=D",
      demoPath: null,
      year: "2021",
      techUsed: "Ionic, Cordova"
    },
    {
      id: 3,
      title: "Spotify Browser",
      description1: "Webpage implementation that supports searching for artists, albums, and tracks in Spotify.",
      icon: sbrowser,
      githubPath: "https://www.google.com/url?q=https%3A%2F%2Fgithub.com%2Fcheryllg%2FSpotify-Browser&sa=D",
      demoPath: null,
      year: "2021",
      techUsed: "Angular"
    }
]
