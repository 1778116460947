import cyclistic from '../assets/images/cyclistic.png'
import bellabeat from '../assets/images/bellabeat.png'

export default [
	{
		id: 1,
		title: "Cyclistic",
		icon: cyclistic,
		description1:
			"Google Data Analytics Capstone Project: Case Study 1. ",
		description2: "Analyzed 12 months of data to provide recommendations for marketing strategies",
		kagglePath: "www.kaggle.com%2Fcheryllgunawan%2Fcyclistic-case-study-1&sa=D",
		demoPath: null,
		year: "2021",
		techUsed: "R, Microsoft Excel",
	},
	{
		id: 2,
		title: "Bellabeat",
		icon: bellabeat,
		description1:
			"Google Data Analytics Capstone Project: Case Study 2.",
		description2:	"Analysed activity of 33 users to provide recommendations for marketing strategies.",
		kagglePath: "www.kaggle.com%2Fcheryllgunawan%2Fbellabeat&sa=D",
		demoPath: null,
		year: "2021",
		techUsed: "R, Microsoft Excel",
	},
];
